'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var index = require('event-bus-vfs/index');
var React = require('react');
var React__default = _interopDefault(React);
var reactViewportProvider = require('react-viewport-provider');
var rsuite = require('rsuite');
require('rsuite/dist/styles/rsuite-default.css');

const MODAL_OPEN = 'ModalV:Modal';

const ModalV = () => {
    const { bp } = reactViewportProvider.useViewport();
    const initialValues = {
        show: false,
        modalTitle: '',
        bodyChildren: null,
        backdrop: true,
        footerChildren: null,
        size: 'md',
        full: false,
        overFlow: true,
        closeButton: true,
        autoFocus: false,
        containerStyleLG: undefined,
        containerStyleMD: undefined,
        containerStyleSM: undefined,
        modalTitleStyle: undefined,
    };
    const [modalObject, setModalObject] = React.useState(initialValues);
    React.useEffect(() => {
        function _onModalOpen(modalVProps) {
            const { show, modalTitle, bodyChildren, backdrop, footerChildren, size, full, overFlow, closeButton, autoFocus, containerStyleLG, containerStyleMD, containerStyleSM, modalTitleStyle, } = modalVProps;
            const defaultValues = {
                show,
                modalTitle,
                bodyChildren,
                backdrop,
                footerChildren,
                size,
                full,
                overFlow,
                closeButton,
                autoFocus,
                containerStyleLG,
                containerStyleMD,
                containerStyleSM,
                modalTitleStyle,
            };
            setModalObject(defaultValues);
        }
        const modalEvents = index.EventBus.on(MODAL_OPEN, _onModalOpen);
        return function cleanup() {
            modalEvents.off();
        };
    }, []);
    const handleClose = () => {
        setModalObject({
            show: false,
            modalTitle: '',
            bodyChildren: null,
            backdrop: true,
            footerChildren: null,
            size: 'md',
            full: false,
            overFlow: true,
            closeButton: true,
            autoFocus: false,
            containerStyleLG: undefined,
            containerStyleMD: undefined,
            containerStyleSM: undefined,
            modalTitleStyle: undefined,
        });
    };
    if (bp === 'xs' || bp === 'sm') {
        return (React__default.createElement("div", { className: "modal-container" },
            React__default.createElement(rsuite.Modal, { backdrop: modalObject.backdrop, show: modalObject.show, onHide: handleClose, size: "xs", style: modalObject.containerStyleSM },
                React__default.createElement(rsuite.Modal.Header, null,
                    React__default.createElement(rsuite.Modal.Title, { styel: modalObject.modalTitleStyle }, modalObject.modalTitle)),
                React__default.createElement(rsuite.Modal.Body, { className: "modal-body" }, modalObject.bodyChildren),
                React__default.createElement(rsuite.Modal.Footer, null, modalObject.footerChildren))));
    }
    if (bp === 'md') {
        return (React__default.createElement("div", { className: "modal-container" },
            React__default.createElement(rsuite.Modal, { backdrop: modalObject.backdrop, show: modalObject.show, onHide: handleClose, size: "md", style: modalObject.containerStyleMD },
                React__default.createElement(rsuite.Modal.Header, null,
                    React__default.createElement(rsuite.Modal.Title, { styel: modalObject.modalTitleStyle }, modalObject.modalTitle)),
                React__default.createElement(rsuite.Modal.Body, { className: "modal-body" }, modalObject.bodyChildren),
                React__default.createElement(rsuite.Modal.Footer, null, modalObject.footerChildren))));
    }
    //bp === 'lg' || bp === 'xl'
    return (React__default.createElement("div", { className: "modal-container" },
        React__default.createElement(rsuite.Modal, { backdrop: modalObject.backdrop, show: modalObject.show, onHide: handleClose, size: modalObject.size, full: modalObject.full, style: modalObject.containerStyleLG },
            React__default.createElement(rsuite.Modal.Header, null,
                React__default.createElement(rsuite.Modal.Title, { styel: modalObject.modalTitleStyle }, modalObject.modalTitle)),
            React__default.createElement(rsuite.Modal.Body, { className: "modal-body" }, modalObject.bodyChildren),
            React__default.createElement(rsuite.Modal.Footer, null, modalObject.footerChildren))));
};

exports.MODAL_OPEN = MODAL_OPEN;
exports.ModalV = ModalV;
